import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import UserLayout from "../../../UI/segments/user/common/UserLayout";

import * as ApiManager from "../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../managers/api/ApiEndPoints";
import DatePicker from "react-datepicker";
import CustomLoader from "../../custom/CustomLoader";

import "react-datepicker/dist/react-datepicker.css";
import ArrowDropDown from "@material-ui/icons/KeyboardArrowDown";
import OutstationJourneyCard from "./userbooking/OutstationJourneyCard";
import {
  MenuItem,
  Select,
  Typography,
  Paper,
  Button,
  Grid,
  CardMedia,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import HolderCarSelection from "./userbooking/HolderCarSelection";
import MapContainer from "./userbooking/MapContainer";
import CustomSnackbar from "../../custom/CustomSnackbar";
import SearchLocation from "./userbooking/SearchLocation";
import ConfirmBookingContainer from "./userbooking/ConfirmBookingContainer";
import AddFavouriteLocation from "./userbooking/AddFavouriteLocation";
import * as SessionManager from "../../../managers/session/SessionManager";
import * as SessionKeys from "../../../managers/session/SessionKeys";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { save_addresses, save_destination } from "../../../actions";
import * as LanguageManager from "../../../managers/language/LanguageManager";
import EventBus from "eventing-bus";

const styles = theme => ({
  godContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px"
    }
  },
  bookingConsole: {
    overflowY: "scroll",
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  mapBox: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none !important"
    },
    width: "55%",
    height: "100%",
    position: "absolute",
    right: 0
  },
  phoneMapBox: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      width: "0%",
      height: "0%",
      position: "none"
    },
    width: "100%",
    height: "30%",
    position: "absolute"
  },
  button: {
    //margin: theme.spacing.unit
  },
  list: {
    width: 300,
    textAlign: "-webkit-center"
  },
  fullList: {
    width: "auto"
  },
  bookingFormContainer: {
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      //marginTop: '60%',
      width: "100vw",
      height: "100vh",
      overflowY: "scroll"
    },

    backgroundColor: "white"
  },
  addressField: {
    fontSize: 14,
    width: "90%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      width: 230
    }
  }
});

class UserBooking extends React.Component {
  state = {
    startLocation: this.props.firstReducer.AddressObject
      ? this.props.firstReducer.AddressObject
      : "",
    dropLocation: this.props.firstReducer.AddressObjects
      ? this.props.firstReducer.AddressObjects
      : "",
    directions: [],
    showRoute: false,
    categoryArray: [],
    anchorEl: null,
    rideType: 1,
    service_id: "",
    package_id: "",
    vehicle_id: "",
    area_id: "",
    isBookingContainerVisible: false,
    isAddFavDilaog: false,
    checkoutData: "",
    laterTime: "",
    isLoading: false,
    bookingFormDirections: [],
    showForm: false,
    serchbar: false,
    selectedType: 0,
    open: false,
    laterDate: LanguageManager.Strings.TODAY_LABEL,
    list: [
      { id: 1, status: LanguageManager.Strings.RIDE_TIME1 },
      { id: 2, status: LanguageManager.Strings.RIDE_TIME2 }
    ],
    showOutstationCard: false,
    OutstationDetails: {},
    oneWayMessage: "",
    targetCity: "",
    sourceCity: "",
    serviceSelectedIndex: 0,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    logOutNow: false,
    checkOutLoad: false
  };

  redirectToDashboard = () => {
    if (this.state.logOutNow) {
      return (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      );
    }
  };

  handleChange(date) {
    let date1 = new Date(date);
    let year = date1.getFullYear();
    let month = date1.getMonth() + 1;
    let dt = date1.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    this.setState({ laterDate: `${year}-${month}-${dt}` });
  }

  showLoader() {
    return <CustomLoader showLoader={this.state.isLoading} />;
  }

  handleSnackBarClose = () => {
    this.setState({
      snakBarMessage: "",
      showSnackbar: false
    });
  };

  handleTimeChange(date) {
    let dateFormat = new Date(date);
    let hours = dateFormat.getHours();
    let min = dateFormat.getMinutes();
    this.setState({ laterTime: `${hours}  : ${min}` });
  }

  executeCheckOutApi = () => {
    if (this.state.startLocation === "") {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter source location.",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else if (this.state.dropLocation === "") {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter Destination location.",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else {
      this.setState({ checkOutLoad: true });
      ApiManager.callPostApi(
        "User booking",
        "Checkout APi",
        ApiEndPoints.APPORIO_TAXI_APIS.CHECKOUT_API,
        {
          service_type: this.state.service_id,
          vehicle_type: this.state.vehicle_id,
          area: this.state.area_id,
          pick_up_locaion: this.state.startLocation.address,
          later_date: this.state.laterDate,
          later_time: this.state.laterTime.replace(/\s/g, ""),
          number_of_rider: "",
          pickup_latitude: String(this.state.startLocation.lat),
          pickup_longitude: String(this.state.startLocation.lng),
          package_id: this.state.package_id,
          drop_location: JSON.stringify([
            {
              stop: "1",
              drop_latitude: String(this.state.dropLocation.lat),
              drop_longitude: String(this.state.dropLocation.lng),
              drop_location: this.state.dropLocation.address,
              status: "1"
            }
          ]),
          total_drop_location: 1,
          booking_type: this.state.rideType,
          return_date: this.state.returnDate ? this.state.returnDate : null,
          return_time: this.state.returnTime ? this.state.returnTime : null
        }
      )
        .then(res => {
          if (res.result === "1") {
            this.setState({
              checkOutLoad: false,
              checkoutData: res.data,
              isBookingContainerVisible: true
            });
          } else {
            this.setState({
              showSnackbar: true,
              snackbarVariant: "error",
              snakBarMessage: res.message,
              isLoading: false,
              checkOutLoad: false
            }); // this.setState({
            //   isBookingContainerVisible: true
            // });
          }
        })
        .catch(err => console.warn(err));
    }
  };

  async componentDidMount() {
    let configData = await SessionManager.getSessionData(
      SessionKeys.USER_CONFIG
    );
    // let sessionData=JSON.parse(configData)
    this.setState({ userConfigData: JSON.parse(configData) });
    this.executeApi();
  }

  executeApi = () => {
    ApiManager.callPostApi(
      "HolderCarSelection",
      "car type api",
      ApiEndPoints.APPORIO_TAXI_APIS.CAR_TYPES,
      {
        latitude:
          this.state.startLocation === ""
            ? "28.412440840413836"
            : String(this.state.startLocation.lat),
        longitude:
          this.state.startLocation === ""
            ? "77.04412076622248"
            : String(this.state.startLocation.lng),

        drop_location: JSON.stringify([
          {
            stop: "1",
            drop_latitude: String(this.state.dropLocation.lat),
            drop_longitude: String(this.state.dropLocation.lng),
            drop_location: this.state.dropLocation.address,
            status: "1"
          }
        ])
      }
    )
      .then(res => {
        console.log("hehehehe" + JSON.stringify(res));
        if (res.result === "1") {
          if (res.data.service_types.length > 0) {
            this.setState({
              categoryArray: res.data.service_types,
              area_id: res.data.id,
              service_id: res.data.service_types[0].id,
              package_id:
                res.data.service_types[0].package.length > 0
                  ? res.data.service_types[0].package[0].id
                  : "",

              vehicle_id:
                res.data.service_types[0].package.length > 0
                  ? res.data.service_types[0].package[0].vehicles[0].id
                  : res.data.service_types[0].vehicles[0].id
            });
          }
        } else {
          this.setState({
            categoryArray: []
          });
        }
      })
      .catch(err => console.warn(err));
  };

  handleClick = event => {
    this.setState({ rideType: event.target.value });
  };
  handleClose = index => {
    this.setState({ anchorEl: null, rideType: index });
  };
  handleAddFavDialogClose = () => {
    this.setState({ open: !this.state.open });
  };
  stopMakingRoute = () => {
    this.setState({ showRoute: !this.state.showRoute });
  };

  getResponse(result) {
    if (result == 4) {
      console.log("GORO" + result);
      this.setState({ outStation: true });
    } else {
      this.setState({ outStation: false });
      console.log("GOPO" + result);
    }
  }

  getDirectionResponse(result) {
    if (result.length > 0) {
      this.setState({ bookingFormDirections: result });
    }
  }

  displayOutstationCard = () => {
    if (this.state.startLocation && this.state.dropLocation) {
      this.setState({ isLoading: true, checkOutLoad: true });
      ApiManager.callPostApi(
        "User Outstation Details",
        "OutstationDetails APi",
        ApiEndPoints.APPORIO_TAXI_APIS.USER_OUTSTATION_DETAILS,
        {
          // city: this.props.firstReducer.AddressObjects?this.props.firstReducer.AddressObjects.selectedCity:this.state.dropLocation.targetCity,
          area: this.state.area_id,
          pickup_lat: this.props.firstReducer.AddressObject
            ? String(this.props.firstReducer.AddressObject.lat)
            : String(this.state.startLocation.lat),
          pickup_long: this.props.firstReducer.AddressObject
            ? String(this.props.firstReducer.AddressObject.lng)
            : String(this.state.startLocation.lng),
          drop_lat: this.props.firstReducer.AddressObjects
            ? String(this.props.firstReducer.AddressObjects.lat)
            : String(this.state.dropLocation.lat),
          drop_long: this.props.firstReducer.AddressObjects
            ? String(this.props.firstReducer.AddressObjects.lng)
            : String(this.state.dropLocation.lng)
        }
      )
        .then(res => {
          console.log("DODODOD" + JSON.stringify(res));
          if (res.result === "1") {
            this.setState(
              {
                checkOutLoad: false,
                OutstnDetails: res.data,
                oneWayMessage: res.message,
                isLoading: false
              },
              () => this.setState({ showOutstationCard: true })
            );
          } else {
            this.setState({
              isLoading: false,
              snackbarVariant: "error",
              snakBarMessage: res.message,
              showSnackbar: true,
              checkOutLoad: false
            });
          }
        })
        .catch(err => console.warn(err));
    } else {
      this.setState({
        snackbarVariant: "warning",
        snakBarMessage: "Please enter start & end locations.",
        showSnackbar: true
      });
    }
  };

  getLogoutResponse(result) {
    EventBus.publish("KILL_HOME", "1");

    console.log("~~~~@~~~~", result);
    if (result) {
      SessionManager.particularKey(SessionKeys.ACCESS_TOKEN);
      this.setState({ logOutNow: true });
    }
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <UserLayout logoutCallBack={this.getLogoutResponse.bind(this)}>
        <div className={classes.godContainer}>
          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />
          {this.redirectToDashboard()}
          {this.showLoader()}
          {!this.state.showOutstationCard ? (
            <div
              style={{
                position: "relative",
                display: "flex",
                height: "100vh"
              }}
            >
              {this.state.isBookingContainerVisible && (
                <ConfirmBookingContainer
                  showFavDriver={
                    this.state.userConfigData.general_config
                      .favourite_driver_module
                  }
                  routeData={this.state.bookingFormDirections}
                  // startLocation={this.state.startLocation}
                  // dropLocation={this.state.dropLocation}
                  bookingType={this.state.rideType}
                  data={this.state.checkoutData}
                  // onBackPress={() =>
                  //   this.setState({
                  //     isBookingContainerVisible: !this.state.isBookingContainerVisible
                  //   })
                  // }
                  onBackPress={() =>
                    this.setState({ serviceSelectedIndex: 0 }, () =>
                      this.setState({ isBookingContainerVisible: false })
                    )
                  }
                />
              )}

              {!this.state.isBookingContainerVisible && !this.state.serchbar ? (
                <Grid className={classes.bookingConsole}>
                  <Paper
                    elevation="0"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    {!this.state.isBookingContainerVisible && (
                      <Grid
                        className={classes.phoneMapBox}

                        // style={{
                        //   width: "55%",
                        //   height: "100%",
                        //   position: "absolute",
                        //   right: 0
                        // }}
                      >
                        <MapContainer
                          directionCallback={this.getDirectionResponse.bind(
                            this
                          )}
                          showRoute={this.state.showRoute}
                          startLocation={this.state.startLocation}
                          dropLocation={this.state.dropLocation}
                        />
                      </Grid>
                    )}
                    <div className={classes.bookingFormContainer}>
                      <CardMedia
                        style={{
                          display: "flex",
                          flex: 1,
                          background: "#EAEAEA",
                          margin: 5,
                          padding: 5,
                          borderRadius: 5,

                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            marginRight: 5,
                            borderRadius: 15 / 2,
                            backgroundColor: "#40D590"
                          }}
                        />
                        <CardMedia
                          style={{ width: "90%" }}
                          onClick={() =>
                            this.setState({
                              serchbar: !this.state.serchbar,
                              selectedType: 1
                            })
                          }
                        >
                          <Typography
                            noWrap={true}
                            className={classes.addressField}
                          >
                            {this.state.startLocation === ""
                              ? LanguageManager.Strings.PICKUP_HINT
                              : this.state.startLocation.address}
                          </Typography>
                        </CardMedia>

                        <CardMedia
                          style={{ width: 40, height: 40 }}
                          onClick={() => {
                            this.state.startLocation === ""
                              ? alert("PLease enter Drop Location")
                              : this.setState({
                                  selectedType: 1,
                                  isAddFavDilaog: !this.state.isAddFavDilaog
                                });
                          }}
                          image="https://cdn2.iconfinder.com/data/icons/media-player-ui/512/Media-Icon-25-512.png"
                          style={{ width: 30, height: 30, cursor: "pointer" }}
                        />
                      </CardMedia>

                      <CardMedia
                        style={{
                          display: "flex",
                          flex: 1,
                          background: "#EAEAEA",
                          margin: 5,
                          padding: 5,
                          borderRadius: 5,

                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            width: 15,
                            height: 15,
                            marginRight: 5,
                            borderRadius: 15 / 2,
                            backgroundColor: "#F14741"
                          }}
                        />
                        <CardMedia
                          style={{ width: "90%" }}
                          onClick={() =>
                            this.setState({
                              serchbar: !this.state.serchbar,
                              selectedType: 2
                            })
                          }
                        >
                          <Typography
                            noWrap={true}
                            className={classes.addressField}
                          >
                            {this.state.dropLocation === ""
                              ? LanguageManager.Strings.DROP_HINT
                              : this.state.dropLocation.address}
                          </Typography>
                        </CardMedia>

                        <CardMedia
                          onClick={() => {
                            this.state.dropLocation === ""
                              ? alert("PLease enter Drop Location")
                              : this.setState({
                                  selectedType: 2,
                                  isAddFavDilaog: !this.state.isAddFavDilaog
                                });
                          }}
                          image="https://cdn2.iconfinder.com/data/icons/media-player-ui/512/Media-Icon-25-512.png"
                          style={{ width: 30, height: 30, cursor: "pointer" }}
                        />
                      </CardMedia>

                      {!this.state.outStation ? (
                        <Select
                          onChange={this.handleClick}
                          value={this.state.rideType}
                          color="#F3F3F3"
                          displayEmpty
                          style={{
                            display: "flex",
                            margin: 5,
                            padding: 2,
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            fontWeight: "bold",
                            backgroundColor: "#F3F3F3",
                            fontSize: 16
                          }}
                          name="Select"
                          disableUnderline={true}
                        >
                          {this.state.list.map((val, index) => (
                            <MenuItem
                              key={index}
                              style={{ fontSize: 16, background: "white" }}
                              value={val.id}
                            >
                              {val.status}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : null}

                      {this.state.rideType === 2 && (
                        <div style={{ width: "100%" }}>
                          {!this.state.outStation ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%"
                              }}
                            >
                              <DatePicker
                                customInput={
                                  <Button
                                    style={{
                                      display: "flex",

                                      background: "#EAEAEA",
                                      padding: "0.5em",
                                      justifyContent: "space-around",
                                      borderRadius: 5,
                                      alignItems: "center",
                                      margin: "0.5rem"
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {LanguageManager.Strings.DATE_LABEL}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        marginLeft: 10,
                                        marginRight: 10
                                      }}
                                    >
                                      {this.state.laterDate}
                                    </Typography>
                                    <IconButton
                                      style={{ padding: 0, margin: 0 }}
                                    >
                                      <ArrowDropDown />
                                    </IconButton>
                                  </Button>
                                }
                                dateFormat="yyyy/MM/dd"
                                minDate={new Date()}
                                showDisabledMonthNavigation
                                onChange={this.handleChange.bind(this)}
                              />
                              <DatePicker
                                customInput={
                                  <Button
                                    style={{
                                      flex: 0.5,
                                      display: "flex",

                                      background: "#EAEAEA",
                                      padding: "0.5em",
                                      justifyContent: "space-around",
                                      borderRadius: 5,
                                      alignItems: "center",
                                      margin: "0.5rem"
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {LanguageManager.Strings.TIME_LABEL}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        marginLeft: 10,
                                        marginRight: 10
                                      }}
                                    >
                                      {this.state.laterTime}
                                    </Typography>
                                    <IconButton
                                      style={{ padding: 0, margin: 0 }}
                                    >
                                      <ArrowDropDown />
                                    </IconButton>
                                  </Button>
                                }
                                onChange={this.handleTimeChange.bind(this)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                dateFormat="h:mm aa"
                                timeCaption={
                                  LanguageManager.Strings.TIME_CAPTION
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      )}

                      <HolderCarSelection
                        callback={this.getResponse.bind(this)}
                        showRoute={this.state.showRoute}
                        indexActive={this.state.serviceSelectedIndex}
                        stopRoute={() => {}}
                        getServiceId={(serviceId, pacakgeId, vehicleId) =>
                          this.setState(
                            {
                              service_id: serviceId,
                              package_id: pacakgeId,
                              vehicle_id: vehicleId
                            },
                            () =>
                              console.log(
                                "nenene" +
                                  serviceId +
                                  "|" +
                                  pacakgeId +
                                  "|" +
                                  vehicleId
                              )
                          )
                        }
                        getPackageId={id => this.setState({ package_id: id })}
                        getVehicleTypeId={id =>
                          this.setState({ vehicle_id: id })
                        }
                        data={this.state.categoryArray}
                      />
                      {this.state.checkOutLoad ? (
                        <Grid
                          item
                          style={{
                            marginLeft: "5%",
                            width: "90%",
                            backgroundColor: "#4392DC",
                            marginTop: "5%",
                            borderRadius: 25
                          }}
                        >
                          <CircularProgress
                            size={27}
                            style={{
                              color: "white",
                              marginTop: "1%",
                              marginBottom: "1%"
                            }}
                          />
                        </Grid>
                      ) : (
                        <Button
                          onClick={() => {
                            !this.state.outStation
                              ? this.executeCheckOutApi()
                              : this.displayOutstationCard();
                          }}
                          style={{
                            marginLeft: "5%",
                            marginRight: "5%",
                            fontSize: 14,
                            backgroundColor: "#4392DC",
                            color: "white",
                            borderRadius: 20,
                            marginTop: "2%"
                          }}
                        >
                          {!this.state.outStation
                            ? LanguageManager.Strings.CONFIRM_BOOKING_BUTTON
                            : LanguageManager.Strings.CONIRM_OUTSTAION_BUTTON}
                        </Button>
                      )}
                    </div>
                  </Paper>
                </Grid>
              ) : (
                this.state.serchbar && (
                  <Grid className={classes.bookingConsole}>
                    <SearchLocation
                      onBackPress={() =>
                        this.setState({ serchbar: !this.state.serchbar })
                      }
                      location={val => {
                        {
                          this.state.selectedType === 1
                            ? this.setState(
                                {
                                  serchbar: !this.state.serchbar,
                                  startLocation: {
                                    ...this.state.startLocation,
                                    lat: val.lat,
                                    lng: val.lng,
                                    address: val.address,
                                    sourceCity: val.selectedCity
                                  }
                                },
                                () => this.executeApi()
                              )
                            : this.setState(
                                {
                                  serchbar: !this.state.serchbar,
                                  dropLocation: {
                                    ...this.state.startLocation,
                                    lat: val.lat,
                                    lng: val.lng,
                                    address: val.address,
                                    targetCity: val.selectedCity
                                  },
                                  showRoute: true
                                },
                                () => this.executeApi()
                              );
                        }
                      }}
                    />
                  </Grid>
                )
              )}

              {!this.state.isBookingContainerVisible && (
                <Grid
                  className={classes.mapBox}

                  // style={{
                  //   width: "55%",
                  //   height: "100%",
                  //   position: "absolute",
                  //   right: 0
                  // }}
                >
                  <MapContainer
                    directionCallback={this.getDirectionResponse.bind(this)}
                    showRoute={this.state.showRoute}
                    startLocation={this.state.startLocation}
                    dropLocation={this.state.dropLocation}
                  />
                </Grid>
              )}

              <AddFavouriteLocation
                close={() =>
                  this.setState({ isAddFavDilaog: !this.state.isAddFavDilaog })
                }
                open={this.state.isAddFavDilaog}
                address={
                  this.state.selectedType === 1
                    ? this.state.startLocation
                    : this.state.dropLocation
                }
              />
            </div>
          ) : (
            <OutstationJourneyCard
              routeData={this.state.bookingFormDirections}
              rideType={this.state.rideType}
              areaID={this.state.area_id}
              startAddress={this.state.startLocation}
              dropAddress={this.state.dropLocation}
              detailsData={this.state.OutstnDetails}
              warningMessage={this.state.oneWayMessage}
              backFunction={() =>
                this.setState({ serviceSelectedIndex: 2 }, () =>
                  this.setState({ showOutstationCard: false })
                )
              }
            />
          )}
        </div>
      </UserLayout>
    );
  }
}

UserBooking.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    firstReducer: state.firstReducer
  };
}

// export default withStyles(styles)(UserBooking);
export default connect(mapStateToProps, { save_addresses, save_destination })(
  withStyles(styles)(UserBooking)
);
