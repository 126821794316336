import React, { Component } from "react";
import {
  Typography,
  List,
  ListItem,
  Grid,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import { CUSTOM_FONT } from "../../../../values/Typography";
import { withStyles } from "@material-ui/core/styles";
import { HomeStyles } from "../../../styles";
import { APPSTORE, GOOGLEPLAY } from "../../../../values/Icons";
import { Link } from "react-router-dom";

let helpData = [
  {
    id: 4,
    heading: "Legal",
    topicList: [
      {
        id: 1,
        name: "Terms & Condition",
        slug: "terms_and_Conditions"
      },
      {
        id: 3,
        name: "Privacy Policy",
        slug: "privacy_policy"
      },
      {
        id: 5,

        name: "Help Center",
        slug: "help_center"
      },
      {
        id: 6,

        name: "About Us",
        slug: "about_us"
      }
    ]
  }
];

const SOCIAL_HANDLES = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/Varadaclub/?ref=bookmarks"
  },
  { name: "Instagram", url: "https://www.instagram.com/varadaclub/" },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/company/varada-club/about/?viewAsMember=true"
  },
  {
    name: "Twitter",
    url:
      "https://twitter.com/intent/tweet/complete?url=https%3A%2F%2Fyoutu.be%2FkfOH28B52ro&text=Varada%20Drivers%20Free%20Registration%20%26%20No%20Commission%21&latest_status_id=1183324264964870145&related=YouTube%2CYouTubeTrends%2CYTCreators&via=YouTube"
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/channel/UCofsTRINV74tiGO3DK-XQQw"
  }
];

class HomeFooterContainer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        style={{
          backgroundColor: this.props.footer.bgColor
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} align="center" md={4}>
          {helpData.map(item => (
            <Grid container key={item.id} justify="center" alignItems="center">
              <div className={classes.listContainer}>
                <ListSubheader
                  align="left"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: this.props.footer.textColor,
                    fontFamily: CUSTOM_FONT
                  }}
                >
                  {item.heading}
                </ListSubheader>

                <List dense={true}>
                  {item.topicList.map((row, index) => (
                    <ListItem key={index}>
                      <Link
                        to={"/" + row.slug}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              onClick={() => this.props.onTermCLick(row.slug)}
                              type="body2"
                              style={{
                                color: this.props.footer.textColor,
                                fontSize: "1rem",
                                // color: this.props.footer.textColor,
                                cursor: "pointer",
                                fontFamily: CUSTOM_FONT
                                //marginLeft: "10%"
                              }}
                              // align="left"
                            >
                              {row.name}
                            </Typography>
                          }
                        />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} align="center" md={4}>
          {SOCIAL_HANDLES.length > 0 ? (
            <Grid container justify="center" alignItems="center">
              <div className={classes.listContainer}>
                <ListSubheader
                  align="left"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: this.props.footer.textColor,
                    fontFamily: CUSTOM_FONT
                  }}
                >
                  Social
                </ListSubheader>

                <List dense={true}>
                  {SOCIAL_HANDLES.map((handleObj, index) => {
                    return (
                      <ListItem key={index}>
                        {/* <Link
                    to={""}
                    style={{ textDecoration: "none" }}
                  > */}
                        <a
                          href={"" + handleObj.url}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color: this.props.footer.textColor,
                                  fontSize: "1rem",
                                  // color: this.props.footer.textColor,
                                  cursor: "pointer",
                                  fontFamily: CUSTOM_FONT
                                  //marginLeft: "10%"
                                }}
                                // align="left"
                              >
                                {handleObj.name}
                              </Typography>
                            }
                          />
                        </a>
                        {/* </Link> */}
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </Grid>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} align="center" md={4}>
          <Typography
            style={{
              fontFamily: CUSTOM_FONT,
              color: this.props.footer.textColor,
              fontSize: "2rem"
            }}
          >
            <ListSubheader
              align="center"
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: this.props.footer.textColor,
                fontFamily: CUSTOM_FONT
              }}
            >
              Download App
            </ListSubheader>
          </Typography>

          <Grid container style={{ width: "100%" }}>
            <Typography
              style={{
                fontFamily: CUSTOM_FONT,
                color: this.props.footer.textColor,
                fontSize: "0.9rem"
              }}
            >
              User Apps
            </Typography>
          </Grid>

          <Grid container spacing={16} alignItems="flex-start">
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <a
                href={this.props.iosLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className={classes.appleImg}
                  alt="complex"
                  src={APPSTORE}
                />
              </a>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <a
                href={this.props.androidLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className={classes.androidImg}
                  alt="complex"
                  src={GOOGLEPLAY}
                />
              </a>
            </Grid>
          </Grid>
          {this.props.androidDriverLink || this.props.iosDriverLink ? (
            <Grid container style={{ width: "100%" }}>
              <Typography
                style={{
                  fontFamily: CUSTOM_FONT,
                  color: this.props.footer.textColor,
                  fontSize: "0.9rem"
                }}
              >
                Driver Apps
              </Typography>
            </Grid>
          ) : null}

          <Grid
            container
            hidden={!(this.props.androidDriverLink && this.props.iosDriverLink)}
            spacing={16}
            alignItems="flex-start"
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              hidden={!this.props.iosDriverLink}
            >
              <a
                href={this.props.iosDriverLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className={classes.appleImg}
                  alt="complex"
                  src={APPSTORE}
                />
              </a>
            </Grid>
            <Grid
              hidden={!this.props.androidDriverLink}
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
            >
              <a
                href={this.props.androidDriverLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className={classes.androidImg}
                  alt="complex"
                  src={GOOGLEPLAY}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(HomeStyles)(HomeFooterContainer);
