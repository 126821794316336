import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Icon,
  Button
} from "@material-ui/core";
import {
  IPHONE_1,
  IPHONE_2,
  IPHONE_3,
  IPHONE_4,
  IPHONE_5,
  TEST
} from "../../../../values/Icons";
import { TimelineStyles } from "../../../styles";
import PrimaryVariantButton from "../../../custom/buttons/PrimaryVariantButton";
import { APPORIO_LOGO } from "../../../../values/Icons";
import CardMedia from "@material-ui/core/CardMedia";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import StepButton from "@material-ui/core/StepButton";

import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import { TAXI_IMAGE_URL } from "../../../../managers/api/ApiEndPoints";

class TimelineComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBorder: null,
      activeStep: 0,
      setActiveStep: 0
    };
  }

  getSteps() {
    return [
      "Create Account",
      "Turn your duty on",
      "View Request",
      "Track Customer",
      "View Receipt"
    ];
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`;
      case 1:
        return "An ad group contains one or more ads which target a shared set of keywords.";
      case 2:
        return `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`;
      default:
        return "Unknown step";
    }
  }

  //    VerticalLinearStepper() {
  //     const {classes }= this.props;
  //     // const [activeStep, setActiveStep] = React.useState(0);
  //     const steps = this.getSteps();
  //    }
  handleNext() {
    this.setState({ activeStep: this.state.activeStep + 1 });
  }

  handleBack() {
    //   this.setActiveStep(prevActiveStep => prevActiveStep - 1);
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleReset() {
    // this.setActiveStep(0);
    this.setState({ activeStep: 0 });
  }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();

    return (
      <Grid item xl style={{ backgroundColor: "#f5f5f5" }}>
        <VerticalTimeline>
          {this.props.DRIVER_LIST.map((row, index) => (
            <VerticalTimelineElement
              key={index}
              style={{ textAlign: "center" }}
              className="vertical-timeline-element--work"
              date={
                <Grid item xs={6} align="center">
                  <img
                    src={row.image}
                    // src={TAXI_IMAGE_URL + row.image}
                    className={classes.DriverProfileUpload}
                  />
                </Grid>
              }
              iconStyle={{ background: "#2DCC70", color: "#ffffff" }}
              justify="center"
              alignItems="center"
              icon={<p className={classes.stepLabel}>{index + 1}</p>}
            >
              <h3 className={classes.stepTitle}>{row.title}</h3>
              <h4 className={classes.stepDetail}>{row.description}</h4>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </Grid>
    );
  }
}

export default withStyles(TimelineStyles)(TimelineComponent);
